import React, { useState, useEffect } from "react";
import Navbar from "../Header/Navbar";
import { FaFireAlt } from "react-icons/fa";
import {
  Box,
  Button,
  HStack,
  Text,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { BiSolidCameraMovie } from "react-icons/bi";
import Footer from "../Footer/Footer";
import axios from "axios";
import * as mod from "./../../url";
import { useNavigate } from "react-router-dom";

const ITEMS_PER_PAGE = 10;

const MovieRequestData = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [authToken, setAuthToken] = useState("YOUR_AUTH_TOKEN"); // Replace with actual auth token
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getdata();
  }, []);

  const handleClick = (direction) => {
    if (
      direction === "next" &&
      currentPage < Math.ceil(data.length / ITEMS_PER_PAGE)
    ) {
      setCurrentPage(currentPage + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const currentData = data.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const getdata = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await axios.get(
        `${mod.api_url}/request/get_details`,
        config
      );
      const fetchedData = response.data;
      setData(fetchedData);
      setLoading(false);
    } catch (error) {
      console.error("Movie request Error:", error);
      toast({
        title: "Error During movie request",
        status: "error",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        className="main"
        style={{ background: "black", height: "100%", overflowY: "auto" }}
      >
        <Navbar />
        <Text textAlign="center" color="white">
          <marquee className="blinking-text">
            <span style={{ display: "inline-flex", alignItems: "center" }}>
              <BiSolidCameraMovie />
              "MovieHub4U: Your ultimate destination for the latest movies,
              streaming, reviews, trailers, and more. Explore, watch, and enjoy
              unlimited entertainment here!"
            </span>
          </marquee>
        </Text>
        <Box className="row" width="100%">
          <TableContainer>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th color="white">Name</Th>
                  <Th color="white">Date</Th>
                  <Th color="white">Movie Name</Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentData.map((item, index) => (
                  <Tr key={index}>
                    <Td color="white">{item.name}</Td>
                    <Td color="white">{item.createdAt.slice(0, 10)}</Td>
                    <Td color="white">{item.movies}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <HStack spacing="24px" justify="center" margin="20px">
          <Button
            onClick={() => handleClick("prev")}
            isDisabled={currentPage === 1}
            colorScheme="teal"
          >
            Previous
          </Button>
          <Text color="white">
            Page {currentPage} of {Math.ceil(data.length / ITEMS_PER_PAGE)}
          </Text>
          <Button
            onClick={() => handleClick("next")}
            isDisabled={currentPage === Math.ceil(data.length / ITEMS_PER_PAGE)}
            colorScheme="teal"
          >
            Next
          </Button>
        </HStack>
        <Footer />
      </div>
    </>
  );
};

export default MovieRequestData;
