import React, { useEffect, useState } from "react";
import Navbar from "../Header/Navbar";
import {
  Box,
  Button,
  Center,
  FormLabel,
  Input,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  HStack,
  Image,
} from "@chakra-ui/react";
import Footer from "../Footer/Footer";
import * as mod from "./../../url";
import axios from "axios";

const ITEMS_PER_PAGE = 2;

const ManageMovies = () => {
  const [categories, setCategories] = useState([]);
  const [editCatId, setEditCatId] = useState(null);
  const [newCatName, setNewCatName] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [movies, setMovies] = useState([]);

  const getCategory = async () => {
    try {
      const { data } = await axios.get(`${mod.api_url}/categry/get_categry`);
      setCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const getMovies = async () => {
    try {
      const response = await axios.get(`${mod.api_url}/movies/get_files`);
      if (response.status === 200) {
        setMovies(response.data);
      } else {
        console.error("Error fetching Movies:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Movies:", error);
    }
  };

  useEffect(() => {
    getCategory();
    getMovies();
  }, []);

  const handleDelete = async (_id) => {
    try {
      await axios.delete(`${mod.api_url}/movies/delete_files/${_id}`);
      setMovies(movies.filter((movie) => movie._id !== _id));
    } catch (error) {
      console.error("Error deleting movie:", error);
    }
  };

  const handleEdit = async () => {
    if (!newCatName || !editCatId) return;
    try {
      const { data } = await axios.put(
        `${mod.api_url}/categry/update_categry/${editCatId}`,
        {
          catName: newCatName,
        }
      );
      setCategories(
        categories.map((cat) => (cat._id === editCatId ? data : cat))
      );
      setEditCatId(null);
      setNewCatName("");
      onClose();
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  const openEditModal = (_id, name) => {
    setEditCatId(_id);
    setNewCatName(name);
    onOpen();
  };

  const handleClick = (direction) => {
    if (
      direction === "next" &&
      currentPage < Math.ceil(filteredMovies.length / ITEMS_PER_PAGE)
    ) {
      setCurrentPage(currentPage + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const filteredMovies = movies.filter(
    (movie) =>
      movie.title &&
      movie.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const currentData = filteredMovies.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        className="main"
        style={{
          background: "black",
          minHeight: "100%",
          width: "100%",
          overflowY: "auto",
        }}
      >
        <Navbar />
        <Box padding="20px" Overflow="auto">
          <Text
            textAlign="center"
            color="white"
            fontSize="xx-large"
            fontWeight="700"
          >
            Manage All Movies
          </Text>
          <Box marginTop="20px">
            <Table variant="simple" color="white">
              <Thead>
                <Tr>
                  <Th color="white">Poster</Th>
                  <Th color="white">Movie Name</Th>
                  <Th color="white">Category</Th>
                  <Th color="white">Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {movies.length > 0 &&
                  movies.map((mov) => (
                    <Tr key={mov._id}>
                      <Td>
                        <Image
                          src={mov.bannerImg}
                          alt="movie banner"
                          width="100px"
                          height="100px"
                        />
                      </Td>
                      <Td>{mov.movieName.slice(0, 20)}</Td>
                      <Td>{mov.category.slice(0, 20)}</Td>
                      <Td>
                        <Button
                          colorScheme="yellow"
                          marginRight={2}
                          onClick={() => openEditModal(mov._id, mov.catName)}
                        >
                          Edit Movie
                        </Button>
                        <Button
                          colorScheme="red"
                          onClick={() => handleDelete(mov._id)}
                        >
                          Delete Movie
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
        <Footer />
        <HStack spacing="24px" justify="center" margin="20px">
          <Button
            onClick={() => handleClick("prev")}
            isDisabled={currentPage === 1}
            colorScheme="teal"
          >
            Previous
          </Button>
          <Text color="white">
            Page {currentPage} of{" "}
            {Math.ceil(filteredMovies.length / ITEMS_PER_PAGE)}
          </Text>
          <Button
            onClick={() => handleClick("next")}
            isDisabled={
              currentPage === Math.ceil(filteredMovies.length / ITEMS_PER_PAGE)
            }
            colorScheme="teal"
          >
            Next
          </Button>
        </HStack>

        {/* Edit Category Modal */}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Movie</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormLabel>Category Name</FormLabel>
              <Input
                placeholder="New Category Name"
                value={newCatName}
                onChange={(e) => setNewCatName(e.target.value)}
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="green" onClick={handleEdit}>
                Save
              </Button>
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </>
  );
};

export default ManageMovies;
