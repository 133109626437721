import { Box, Button, Center, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { TiThMenu } from "react-icons/ti";
import "./Screen.css";
import { BiSolidCameraMovie } from "react-icons/bi";
import { FaFireAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import * as mod from "./../../url";
import axios from "axios";
import { Helmet } from "react-helmet";

const colors = [
  "red",
  "blue",
  "green",
  "pink",
  "orange",
  "purple",
  "teal",
  "cyan",
  "yellow",
];

const Category = () => {
  const [categories, setCategories] = useState([]);

  const getCategory = async () => {
    try {
      const { data } = await axios.get(`${mod.api_url}/categry/get_categry`);
      setCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleCardClick = () => {
    window.open("https://stake.com/?c=VvyhynH8");
    window.open("https://bc.co/?i=24mwusws5&utm_source=24mwusws5", "_blank");
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          ATOZMOVIES | Download All Bollywood and Hollywood Movies, South Indian
          Movies ...
        </title>
        <meta
          name="description"
          content="ATOZMOVIES is a platform offering downloads of Bollywood, Hollywood, and South Indian movies. It provides a variety of films across genres, making it a go-to destination for movie enthusiasts."
        />
        <meta
          name="keywords"
          content="Movie download
Free movies,
Bollywood movies,
Hollywood movies,
South Indian movies,
Latest movies,
HD movies,
Full movies,
Action movies,
Romance movies,
Thriller movies,
Comedy movies,
Movie streaming,
Movie trailers,
New releases,
Dubbed movies,
Tamil movies,
Telugu movies,
Hindi movies,
English movies,
4K movies,
Movie sites,
Online movies,
Blockbuster movies,
Film download,
Cinema movies,
Web series,
Movie collections,
Movie download links,
Free streaming,"
        />
        <link rel="canonical" href="https://atozmovies.store" />
      </Helmet>
      <Center>
        {" "}
        <nav
          className="navbar navbar-expand-lg bg-body-black"
          style={{ background: "black" }}
        >
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style={{ color: "white" }}
            >
              <TiThMenu />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <span className="navbar-nav me-auto mb-1 mb-lg-0">
                <span className="nav-item" onClick={handleCardClick}>
                  <Box
                    width="100%"
                    display="flex"
                    flexWrap="wrap"
                    overflowX="auto"
                  >
                    {categories?.map((cat, index) => (
                      <div key={cat.id}>
                        {" "}
                        <Button
                          as={Link}
                          to={`/category-filter-movies/${cat.catName}`}
                          marginRight={2}
                          colorScheme={colors[index % colors.length]}
                          margin={1}
                        >
                          {cat.catName}
                        </Button>
                      </div>
                    ))}
                  </Box>
                </span>
              </span>
            </div>
          </div>
        </nav>
      </Center>
      <hr />
      <div
        style={{
          borderTop: "1px solid white",
          borderBottom: "1px solid white",
        }}
      ></div>
      <Box display="flex" marginTop="10px" justifyContent="center">
        <FaFireAlt
          style={{
            color: "yellow",
            width: "40px",
            height: "40px",
            marginRight: "20px",
          }}
        />
        <Text color="orange" fontFamily="lemon" fontSize="xx-large">
          Latest Releases
        </Text>
      </Box>
    </>
  );
};

export default Category;
