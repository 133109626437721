import React, { useEffect, useState } from "react";
import Navbar from "../Header/Navbar";
import {
  Box,
  Button,
  Center,
  FormLabel,
  Input,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import Footer from "../Footer/Footer";
import * as mod from "./../../url";
import axios from "axios";

const ManageCategory = () => {
  const [categories, setCategories] = useState([]);
  const [editCatId, setEditCatId] = useState(null);
  const [newCatName, setNewCatName] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getCategory = async () => {
    try {
      const { data } = await axios.get(`${mod.api_url}/categry/get_categry`);
      setCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleDelete = async (_id) => {
    try {
      await axios.delete(`${mod.api_url}/categry/delete_categry/${_id}`);
      setCategories(categories.filter((cat) => cat._id !== _id));
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const handleEdit = async () => {
    if (!newCatName || !editCatId) return;
    try {
      const { data } = await axios.put(
        `${mod.api_url}/categry/update_categry/${editCatId}`,
        {
          catName: newCatName,
        }
      );
      setCategories(
        categories.map((cat) => (cat._id === editCatId ? data : cat))
      );
      setEditCatId(null);
      setNewCatName("");
      onClose();
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  const openEditModal = (_id, name) => {
    setEditCatId(_id);
    setNewCatName(name);
    onOpen();
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        className="main"
        style={{ background: "black", minHeight: "100%", overflowY: "auto" }}
      >
        <Navbar />
        <Box padding="20px">
          <Text
            textAlign="center"
            color="white"
            fontSize="xx-large"
            fontWeight="700"
          >
            Manage Category
          </Text>
          <Box marginTop="20px">
            <Table variant="simple" color="white">
              <Thead>
                <Tr>
                  <Th color="white">Category Name</Th>
                  <Th color="white">Date</Th>
                  <Th color="white">Time</Th>
                  <Th color="white">Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {categories.length > 0 &&
                  categories.map((cat) => (
                    <Tr key={cat._id}>
                      <Td>{cat.catName}</Td>
                      <Td>{cat.createdAt.slice(0, 10)}</Td>
                      <Td>{cat.createdAt.slice(11, 16)}</Td>
                      <Td>
                        <Button
                          colorScheme="yellow"
                          marginRight={2}
                          onClick={() => openEditModal(cat._id, cat.catName)}
                        >
                          Edit
                        </Button>
                        <Button
                          colorScheme="red"
                          onClick={() => handleDelete(cat._id)}
                        >
                          Delete
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
        <Footer />

        {/* Edit Category Modal */}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Category</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormLabel>Category Name</FormLabel>
              <Input
                placeholder="New Category Name"
                value={newCatName}
                onChange={(e) => setNewCatName(e.target.value)}
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="green" onClick={handleEdit}>
                Save
              </Button>
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </>
  );
};

export default ManageCategory;
