import React, { useEffect, useState } from "react";
import { Box, Center, Container, Text } from "@chakra-ui/react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import * as mod from "./../../url";
import axios from "axios";
import Navbar from "../Header/Navbar";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const submitHandler = async () => {
    setLoading(true);

    if (!email || !password) {
      toast({
        title: "Please fill all the blanks.",
        status: "warning",
        duration: 500,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
      return;
    }

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const response = await axios.post(
        `${mod.api_url}/auth/login`,
        { email, password },
        config
      );

      const data = response.data;
      localStorage.setItem("SuperAdminInfo", JSON.stringify(data));
      toast({
        title: "Login Successful.",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      navigate("/");
      setLoading(false);
    } catch (error) {
      toast({
        title: "Error During Login.",
        status: "error",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="main" style={{ background: "black", height: "100vh" }}>
        <Navbar />
        <div className="login">
          <Container centerContent>
            <Center>
              <span className="navbar-brand">
                <Link to="/">
                  <img
                    src="hdhub4ulogo.png"
                    style={{ width: "250px", height: "auto" }}
                  />
                </Link>
              </span>
            </Center>

            <Text fontSize="large" color="white">
              Admin Login
            </Text>
          </Container>
          <Container centerContent>
            <Box
              p={3}
              width="100%"
              border="1px solid #e2e8f0"
              borderRadius="10px"
              borderWidth="1px"
            >
              <FormControl id="email" isRequired>
                <FormLabel color="white">Enter Your Email</FormLabel>
                <Input
                  color="white"
                  placeholder="Enter Your email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel color="white">New Password</FormLabel>
                <InputGroup>
                  <Input
                    type={show ? "text" : "password"}
                    placeholder="Enter  Your Password"
                    value={password}
                    color="white"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Button
                colorScheme="pink"
                width="100%"
                marginTop={3}
                onClick={submitHandler}
              >
                Login
              </Button>
            </Box>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Login;
