import {
  Box,
  Button,
  Center,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";
import { TiThMenu } from "react-icons/ti";

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("SuperAdminInfo") !== null);
  }, []);

  const {
    isOpen: iscountryOpen,
    onOpen: oncountryOpen,
    onClose: oncountryClose,
  } = useDisclosure();

  const logoutHandler = () => {
    localStorage.removeItem("SuperAdminInfo");
    setIsLoggedIn(false);
    window.location.reload();
  };

  const handleCardClick = () => {
    window.open("https://loanwaystore.com/eduvanz-quick-education-app");
    window.open("https://stake.com/?c=VvyhynH8", "_blank");
    // setTimeout(() => {
    //   window.open("https://bc.co/?i=24mwusws5&utm_source=24mwusws5", "_blank");
    // }, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <nav
        className="navbar navbar-expand-lg bg-body-black"
        style={{ background: "black" }}
      >
        <div className="container-fluid">
          <span className="navbar-brand">
            <Link to="/">
              <img src="/atozmovies.jpg" alt="Logo" style={{ width: "80px" }} />
            </Link>
          </span>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{ color: "white" }}
          >
            <TiThMenu />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <span className="navbar-nav me-auto mb-1 mb-lg-0">
              <span className="nav-item">
                <Link to="/disclimer">
                  <Button
                    marginRight={2}
                    colorScheme=""
                    to="/"
                    onClick={handleCardClick}
                  >
                    Disclimer
                  </Button>
                </Link>
                <Link to="/how-to-download">
                  <Button
                    marginRight={2}
                    colorScheme=""
                    to="/"
                    onClick={handleCardClick}
                  >
                    How to Download ?
                  </Button>
                </Link>
                <Link to="/join-group">
                  <Button
                    marginRight={2}
                    colorScheme=""
                    to="/"
                    onClick={handleCardClick}
                  >
                    Join Our Group !
                  </Button>
                </Link>
                <Link to="/movie-request">
                  <Button
                    marginRight={2}
                    colorScheme=""
                    to="/"
                    onClick={handleCardClick}
                  >
                    Movie Request Page
                  </Button>
                </Link>
                <span className="nav-item">
                  {isLoggedIn && (
                    <Menu>
                      <MenuButton
                        colorScheme="pink"
                        as={Button}
                        rightIcon={<IoMdArrowDropdown />}
                        onClick={oncountryOpen}
                      >
                        Admin Access
                      </MenuButton>
                      <MenuList marginTop={7}>
                        <Box>
                          <Modal
                            isOpen={iscountryOpen}
                            onClose={oncountryClose}
                          >
                            <ModalOverlay />
                            <ModalContent>
                              <ModalHeader textAlign="center">
                                Manage your System
                              </ModalHeader>
                              <ModalCloseButton />
                              <ModalBody>
                                <Link to="/post-movies-8374fefg7f4f48f74v47vgf743fg437f47g47gc47">
                                  <Button
                                    width="100%"
                                    colorScheme="blue"
                                    mr={3}
                                    marginBottom={2}
                                  >
                                    Post Movies
                                  </Button>
                                </Link>
                                <Link to="/manage-movies-8374fefg7f4f48f74v47vgf743fg437f47g47gc4">
                                  <Button
                                    width="100%"
                                    colorScheme="blue"
                                    mr={3}
                                    marginBottom={2}
                                  >
                                    Manage Movies
                                  </Button>
                                </Link>
                                <Link to="/create-category-8374fefg7f4f48f74v47vgf743fg437f47g47gc47">
                                  <Button
                                    width="100%"
                                    colorScheme="blue"
                                    mr={3}
                                    marginBottom={2}
                                  >
                                    Create Category
                                  </Button>
                                </Link>
                                <Link to="/manage-category-8374fefg7f4f48f74v47vgf743fg437f47g47gc47">
                                  <Button
                                    width="100%"
                                    colorScheme="blue"
                                    mr={3}
                                    marginBottom={2}
                                  >
                                    Manage Category
                                  </Button>
                                </Link>

                                <Link to="/movie-requests">
                                  <Button
                                    width="100%"
                                    colorScheme="blue"
                                    mr={3}
                                  >
                                    Request Movies
                                  </Button>
                                </Link>
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  width="40%"
                                  colorScheme="blue"
                                  mr={3}
                                  onClick={oncountryClose}
                                >
                                  Close
                                </Button>
                              </ModalFooter>
                            </ModalContent>
                          </Modal>
                        </Box>
                      </MenuList>
                    </Menu>
                  )}
                </span>
              </span>
            </span>
            <form className="d-flex" role="search">
              <span className="nav-item">
                <span className="nav-link">
                  {isLoggedIn ? (
                    <Button
                      marginRight={2}
                      colorScheme="blue"
                      onClick={logoutHandler}
                    >
                      Logout
                    </Button>
                  ) : (
                    <Link to="/login">
                      <Button marginRight={2} colorScheme="blue">
                        Login
                      </Button>
                    </Link>
                  )}
                </span>
              </span>
            </form>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
