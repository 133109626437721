import React, { useEffect, useState } from "react";
import { Box, Center, Container, Text, useDisclosure } from "@chakra-ui/react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import * as mod from "./../../url";
import axios from "axios";
import Navbar from "../Header/Navbar";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

const Forget = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [authToken, setAuthToken] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [disabled, setDisabled] = useState(false);
  const handleClick = () => setShow(!show);

  const generateOTP = async () => {
    setDisabled(true);
    onOpen();

    setTimeout(() => {
      setDisabled(false);
    }, 5000);

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await axios.post(
        `${mod.api_url}/auth/generate-otp`,
        { email },
        config
      );
      if (response.data && response.data.success) {
        toast({
          title: "OTP generated successfully.",
          status: "success",
          duration: 1000,
          isClosable: true,
          position: "bottom",
        });
        console.log(response, "otp");
      } else {
        toast({
          title: "Failed to generate OTP.",
          status: "error",
          duration: 1000,
          isClosable: true,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error("OTP Generation Error:", error);
      toast({
        title: "Error generating OTP {backend error}.",
        status: "error",
        duration: 1000,
        isClosable: true,
        position: "bottom",
      });
    }
  };

  const submitHandler = async () => {
    onClose();
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await axios.post(
        `${mod.api_url}/auth/forgot-password`,
        { otp, newPassword: password, email },
        config
      );
      const data = response.data;
      if (data && data.success) {
        toast({
          title: "Forget  Successful.",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
        navigate("/");
      } else {
        toast({
          title: "Invalid credentials.",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
      setLoading(false);
      navigate("/");
    } catch (error) {
      console.error("Login Error:", error);
      toast({
        title: "Error During Forget Password",
        status: "error",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="main" style={{ background: "black", height: "100vh" }}>
        <Navbar />
        <div className="login">
          <Container centerContent>
            <Center>
              <span className="navbar-brand">
                <Link to="/">
                  <img
                    src="hdhub4ulogo.png"
                    style={{ width: "250px", height: "auto" }}
                  />
                </Link>
              </span>
            </Center>

            <Text fontSize="large" color="white">
              Admin Forget Password
            </Text>
          </Container>
          <Container
            centerContent
            p={4}
            borderWidth="1px"
            border="1px solid #e2e8f0"
            borderRadius="10px"
          >
            <FormControl id="email" isRequired>
              <FormLabel color="white">Enter Your Email</FormLabel>
              <Input
                color="white"
                placeholder="Enter Your email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </FormControl>

            <Button
              width="100%"
              marginTop={3}
              colorScheme="blue"
              onClick={generateOTP}
              disabled={disabled}
            >
              {disabled ? "OTP Generated " : " Regenerate OTP"}
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader textAlign="center">Verify with OTP</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl id="otp" isRequired>
                    <FormLabel>Enter OTP</FormLabel>
                    <InputGroup>
                      <Input
                        placeholder="Enter Your OTP"
                        onChange={(e) => setOtp(e.target.value)}
                        value={otp}
                        width="100%"
                        marginRight={2}
                        color="black"
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl id="password" isRequired>
                    <FormLabel>New Password</FormLabel>
                    <InputGroup>
                      <Input
                        color="black"
                        type={show ? "text" : "password"}
                        placeholder="Enter  Your Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="red"
                    width="50%"
                    mr={3}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    colorScheme="blue"
                    width="50%"
                    onClick={submitHandler}
                  >
                    Submit
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Forget;
