import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage'
const firebaseConfig = {
    apiKey: "AIzaSyAgYNV_fM2Upfd6NH8_zlGUGblqkgiTHTw",
    authDomain: "hdmoviehub4u.firebaseapp.com",
    projectId: "hdmoviehub4u",
    storageBucket: "hdmoviehub4u.appspot.com",
    messagingSenderId: "490983995024",
    appId: "1:490983995024:web:83f7b2f7572be461800a15",
    measurementId: "G-TDRL34MS0S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export default storage;