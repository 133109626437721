import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./Components/Home/Home";
import MoviesDetails from "./Components/MoviesDetails/MoviesDetails";
import Disclimer from "./Components/Pages/Disclimer";
import Howtodownload from "./Components/Pages/Howtodownload";
import Joingroup from "./Components/Pages/Joingroup";
import MovieRequest from "./Components/Pages/MovieRequest";
import Login from "./Components/Admin/Login";
import PostMovies from "./Components/Admin/PostMovies";
import Register from "./Components/Admin/Register";
import Forget from "./Components/Admin/Forget";
import MovieRequestData from "./Components/Admin/MovieRequestData";
import CategoryMovie from "./Components/Screens/CategoryMovie";
import CreateCategory from "./Components/Admin/CreateCategory";
import ManageCategory from "./Components/Admin/ManageCategory";
import ManageMovies from "./Components/Admin/ManageMovies";
import { BiDownload } from "react-icons/bi";
import DownloadPage from "./Components/MoviesDetails/DownloadPage";
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/movies-details/:id" element={<MoviesDetails />} exact />
          <Route
            path="/movie-download-evh7fy7vhe7vr7e8e7c-evervevevevre8vuev0e--dvfvrefhfhfh-ef9urfj-vjrv"
            element={<DownloadPage />}
            exact
          />
          <Route path="/disclimer" element={<Disclimer />} exact />
          <Route path="/how-to-download" element={<Howtodownload />} exact />
          <Route path="/join-group" element={<Joingroup />} exact />
          <Route path="/movie-request" element={<MovieRequest />} exact />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} exact />
          <Route
            path="/category-filter-movies/:catName"
            element={<CategoryMovie />}
            exact
          />
          <Route path="/login" element={<Login />} exact />
          <Route
            path="/register-admin-for-upload-movie-eu7f7327bc7e6f37fcuc"
            element={<Register />}
            exact
          />
          <Route
            path="/forget-password-admin-for-forget-password-movie-eu7f7327bc7e6f37fcu"
            element={<Forget />}
            exact
          />
          <Route
            path="/post-movies-8374fefg7f4f48f74v47vgf743fg437f47g47gc47"
            element={<PostMovies />}
            exact
          />
          <Route
            path="/create-category-8374fefg7f4f48f74v47vgf743fg437f47g47gc47"
            element={<CreateCategory />}
            exact
          />
          <Route
            path="/manage-category-8374fefg7f4f48f74v47vgf743fg437f47g47gc47"
            element={<ManageCategory />}
            exact
          />
          <Route
            path="/manage-movies-8374fefg7f4f48f74v47vgf743fg437f47g47gc4"
            element={<ManageMovies />}
            exact
          />
          <Route path="/movie-requests" element={<MovieRequestData />} exact />
        </Routes>
      </Router>
    </>
  );
}

export default App;
