import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import { Paper, useMantineTheme } from "@mantine/core";
import axios from "axios";
import * as mod from "./../../url";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css"; // Corrected import
import { Helmet } from "react-helmet";

const MovieSlider = ({ image, title, category, navigateTo }) => {
  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);
  return (
    <Paper
      shadow="md"
      p="xl"
      radius="md"
      style={{
        backgroundImage: `url(${image})`,
        height: "180px",
        width: "96%",
        margin: "1px",
        marginTop: "2px",
        marginBottom: "2px",
        backgroundSize: "cover",
        backgroundPosition: "center",
        cursor: "pointer",
      }}
      onClick={navigateTo}
    ></Paper>
  );
};

function Demo() {
  const [movies, setMovies] = useState([]);
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
  const tablet = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
  const navigate = useNavigate();

  const getMovies = async () => {
    try {
      const response = await axios.get(`${mod.api_url}/movies/get_files`);
      if (response.status === 200) {
        setMovies(response.data);
      } else {
        console.error("Error fetching Movies:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Movies:", error);
    }
  };

  useEffect(() => {
    getMovies();
  }, []);

  const handleCardClick = () => {
    window.open("https://loanwaystore.com");
    window.open("https://bc.co/?i=24mwusws5&utm_source=24mwusws5");
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 600 },
      items: 10,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 3,
      slidesToSlide: 1,
    },
  };

  return (
    <div style={{ background: "black" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          ATOZMOVIES | Download All Bollywood and Hollywood Movies, South Indian
          Movies ...
        </title>
        <meta
          name="description"
          content="ATOZMOVIES is a platform offering downloads of Bollywood, Hollywood, and South Indian movies. It provides a variety of films across genres, making it a go-to destination for movie enthusiasts."
        />
        <meta
          name="keywords"
          content="Movie download
Free movies,
Bollywood movies,
Hollywood movies,
South Indian movies,
Latest movies,
HD movies,
Full movies,
Action movies,
Romance movies,
Thriller movies,
Comedy movies,
Movie streaming,
Movie trailers,
New releases,
Dubbed movies,
Tamil movies,
Telugu movies,
Hindi movies,
English movies,
4K movies,
Movie sites,
Online movies,
Blockbuster movies,
Film download,
Cinema movies,
Web series,
Movie collections,
Movie download links,
Free streaming,"
        />
        <link rel="canonical" href="https://atozmovies.store" />
      </Helmet>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={3000}
        // showDots={true}
        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        infinite={true}
      >
        {movies.map((item, index) => (
          <div key={index} onClick={handleCardClick}>
            <MovieSlider
              image={item.bannerImg}
              title={item.title}
              category={item.category}
              navigateTo={() => navigate(`/movies-details/${item._id}`)}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default Demo;
