import React, { useEffect, useState } from "react";
import { Box, Center, Container, Text } from "@chakra-ui/react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import * as mod from "./../../url";
import axios from "axios";
import Navbar from "../Header/Navbar";

const Register = (props) => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const submitHandler = async () => {
    setLoading(true);

    // Validate user input
    if (!username || !password || !email) {
      toast({
        title: "Please fill all the blanks.",
        status: "warning",
        duration: 500,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
      return;
    }

    try {
      const requestData = {
        username,
        email,
        password,
      };

      const { data } = await axios.post(
        `${mod.api_url}/auth/signup`,
        requestData
      );
      // Handle success
      toast({
        title: "Sign-up Successful.",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
      navigate("/");
      setEmail("");
      setPassword("");
      setUsername("");
    } catch (error) {
      // Handle errors
      console.error("Registration Error:", error);
      toast({
        title: "Error during Register.",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="main" style={{ background: "black", height: "100vh" }}>
        <Navbar />
        <div className="login">
          <Container centerContent>
            <Center>
              <span className="navbar-brand">
                <Link to="/">
                  <img
                    src="hdhub4ulogo.png"
                    style={{ width: "250px", height: "auto" }}
                  />
                </Link>
              </span>
            </Center>

            <Text fontSize="large" color="white">
              Admin Register
            </Text>
          </Container>
          <Container centerContent>
            <Box
              p={3}
              width="100%"
              border="1px solid #e2e8f0"
              borderRadius="10px"
              borderWidth="1px"
            >
              <FormControl id="name" isRequired>
                <FormLabel color="white">Name</FormLabel>
                <Input
                  color="white"
                  placeholder="Enter Your Name"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                />
              </FormControl>
              <FormControl id="email" isRequired>
                <FormLabel color="white">Enter Your Email</FormLabel>
                <Input
                  color="white"
                  placeholder="Enter Your email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </FormControl>

              <FormControl id="password" isRequired>
                <FormLabel color="white"> Password</FormLabel>
                <InputGroup>
                  <Input
                    type={show ? "text" : "password"}
                    placeholder="Enter  Your Password"
                    value={password}
                    color="white"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Button
                colorScheme="pink"
                width="100%"
                marginTop={3}
                onClick={submitHandler}
              >
                Register
              </Button>
            </Box>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Register;
