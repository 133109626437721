import React, { useEffect, useState } from "react";
import Navbar from "../Header/Navbar";
import {
  Box,
  Button,
  Center,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Select } from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/react";
import Footer from "../Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import * as mod from "./../../url";
import axios from "axios";

const CreateCategory = () => {
  const [catName, setCatName] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  const submitHandler = async () => {
    setLoading(true);

    // Validate user input
    if (!catName) {
      toast({
        title: "Please fill all the blanks.",
        status: "warning",
        duration: 500,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
      return;
    }

    try {
      const requestData = {
        catName,
      };

      const { data } = await axios.post(
        `${mod.api_url}/categry/post_categry`,
        requestData
      );
      setCatName("");
      toast({
        title: "create category Successful.",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      // Handle errors
      console.error("create category Error:", error);
      toast({
        title: "Error during create category.",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
    }
  };

  const getCategory = async () => {
    try {
      const { data } = await axios.get(`${mod.api_url}/categry/get_categry`);
      setCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="main" style={{ background: "black", height: "100%" }}>
        <Navbar />
        <Box>
          <Text
            textAlign="center"
            color="white"
            fontSize="xx-large"
            fontWeight="700"
          >
            Create Category
          </Text>
        </Box>
        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-6">
            <FormLabel color="white" marginTop={3}>
              Enter Category Name
            </FormLabel>
            <Input
              placeholder="Enter Category Name"
              color="white"
              p={1}
              value={catName}
              onChange={(e) => setCatName(e.target.value)}
            />
          </div>
          <div className="col-md-6">
            <FormLabel color="black" marginTop={3}>
              .
            </FormLabel>
            <Center>
              <Button colorScheme="green" width="40%" onClick={submitHandler}>
                Create Category
              </Button>
            </Center>
          </div>

          <Box marginTop="20px">
            {categories.length > 0 &&
              categories.map((cat) => (
                <div key={cat.id}>
                  <Button
                    as={Link}
                    to={`/categories/${cat.id}`}
                    marginRight={2}
                    colorScheme="green"
                    width="100%"
                    marginBottom={2}
                  >
                    {cat.catName}
                  </Button>
                </div>
              ))}
          </Box>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default CreateCategory;
