import React, { useEffect, useState } from "react";
import {
  Flex,
  Card,
  Image,
  Heading,
  Text,
  Button,
  HStack,
  Container,
  Box,
  Select,
  Center,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import * as mod from "./../../url";
import "./Screen.css";
import { FaStar } from "react-icons/fa6";
import { FaLongArrowAltRight, FaTelegram } from "react-icons/fa";
import { BiSolidCameraMovie } from "react-icons/bi";
import { FaFireAlt } from "react-icons/fa";
import { TiThMenu } from "react-icons/ti";
import { Helmet } from "react-helmet";

const ITEMS_PER_PAGE = 30;

const colors = [
  "red",
  "blue",
  "green",
  "pink",
  "orange",
  "purple",
  "teal",
  "cyan",
  "yellow",
];

const Movies = () => {
  const [movies, setMovies] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMovies, setFilteredMovies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [categories, setCategories] = useState([]);
  const [showAd, setShowAd] = useState(true);

  const navigate = useNavigate();

  const getMovies = async () => {
    try {
      const response = await axios.get(`${mod.api_url}/movies/get_files`);
      if (response.status === 200) {
        setMovies(response.data);
        setFilteredMovies(response.data);
      } else {
        console.error("Error fetching Movies:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Movies:", error);
    }
  };

  useEffect(() => {
    getMovies();
  }, []);

  const getCategory = async () => {
    try {
      const { data } = await axios.get(`${mod.api_url}/categry/get_categry`);
      setCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleChange = (event) => {
    const selectedCategory = event.target.value;
    if (selectedCategory) {
      navigate(`/category-filter-movies/${selectedCategory}`);
    }
  };

  useEffect(() => {
    const newIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    setStartIndex(newIndex);
  }, [currentPage]);

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    const filteredResults = movies.filter((movie) =>
      movie.movieName.toLowerCase().includes(searchValue)
    );
    setFilteredMovies(filteredResults);
    setCurrentPage(1); // Reset currentPage when search term changes
  };

  const handleClick = (type) => {
    if (type === "prev") {
      setCurrentPage((prev) => prev - 1);
    } else if (type === "next") {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const displayedMovies = filteredMovies.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  const handleCardClick = () => {
    window.open("https://loanwaystore.com");
    // window.open("https://bc.co/?i=24mwusws5&utm_source=24mwusws5");
    // setTimeout(() => {
    //   window.open("https://loanwaystore.com", "_blank");
    // }, 0);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAd(false);
    }, 45000);

    return () => clearTimeout(timer);
  }, []);

  const handleCloseAd = () => {
    setShowAd(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          ATOZMOVIES | Download All Bollywood and Hollywood Movies, South Indian
          Movies ...
        </title>
        <meta
          name="description"
          content="ATOZMOVIES is a platform offering downloads of Bollywood, Hollywood, and South Indian movies. It provides a variety of films across genres, making it a go-to destination for movie enthusiasts."
        />
        <meta
          name="keywords"
          content="Movie download
Free movies,
Bollywood movies,
Hollywood movies,
South Indian movies,
Latest movies,
HD movies,
Full movies,
Action movies,
Romance movies,
Thriller movies,
Comedy movies,
Movie streaming,
Movie trailers,
New releases,
Dubbed movies,
Tamil movies,
Telugu movies,
Hindi movies,
English movies,
4K movies,
Movie sites,
Online movies,
Blockbuster movies,
Film download,
Cinema movies,
Web series,
Movie collections,
Movie download links,
Free streaming,"
        />
        <link rel="canonical" href="https://atozmovies.store" />
      </Helmet>
      <hr style={{ color: "white" }} />
      <Box>
        <nav
          className="navbar navbar-expand-lg bg-body-black"
          style={{ background: "black" }}
        >
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style={{ color: "white", marginBottom: "7px" }}
            >
              <TiThMenu />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <span className="navbar-nav me-auto mb-1 mb-lg-0">
                <span className="nav-item" onClick={handleCardClick}>
                  <Box
                    width="100%"
                    display="flex"
                    flexWrap="wrap"
                    overflowX="auto"
                  >
                    {categories?.map((cat, index) => (
                      <div key={cat.id}>
                        <Center>
                          <Button
                            as={Link}
                            to={`/category-filter-movies/${cat.catName}`}
                            marginRight={2}
                            colorScheme={colors[index % colors.length]}
                            margin={1}
                          >
                            {cat.catName}
                          </Button>
                        </Center>
                      </div>
                    ))}{" "}
                  </Box>
                </span>
              </span>
            </div>
          </div>
        </nav>{" "}
      </Box>
      <div
        style={{
          borderTop: "1px solid white",
          borderBottom: "1px solid white",
        }}
      >
        <div className="alert1" id="myDIV">
          <span
            className="closebtn"
            onclick="if (!window.__cfRLUnblockHandlers) return false; this.parentElement.style.display='none';"
          >
            ×
          </span>
          <center>
            <font size="3px">
              <b style={{ fontWeight: 400, color: "white" }}>
                Welcome to the <b className="hd">ATOZ</b>
                <b className="hub">MOVIES</b> 👉
                <b>
                  <a
                    href="https://telegram.me/moviehub4u143"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "blue" }}
                  >
                    Join Our Telegram Channel
                  </a>
                </b>
                | 🔖 Remember our domain <b className="hd">ATOZ</b>
                <b className="hub">MOVIES</b>
                <b></b> to visit us.
              </b>
            </font>
          </center>
        </div>

        <hr style={{ color: "white" }} />

        <Text textAlign="center" color="white" background="#fe7c3d">
          <marquee className="blinking-text">
            <span style={{ display: "inline-flex", alignItems: "center" }}>
              <BiSolidCameraMovie />
              "ATOZMOVIES: Your ultimate destination for the latest movies,
              streaming, reviews, trailers, and more. Explore, watch, and enjoy
              unlimited entertainment here!"
            </span>
          </marquee>
        </Text>
      </div>

      <Center>
        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-12">
            <Box display="flex" marginTop="10px" justifyContent="center">
              <FaFireAlt
                style={{
                  color: "white",
                  width: "40px",
                  height: "40px",
                  marginRight: "20px",
                }}
              />
              <Text color="white" fontFamily="lemon" fontSize="xx-large">
                Latest Releases
              </Text>
            </Box>
            <Center>
              {" "}
              <input
                className="form-control me-2"
                type="text"
                placeholder="Write hear Movies Name Search movies..."
                value={searchTerm}
                onChange={handleSearch}
                style={{
                  width: "60%",
                  marginBottom: "20px",
                  marginTop: "10px",
                }}
              />
            </Center>

            <Flex
              flexWrap="wrap"
              justifyContent="center"
              onClick={handleCardClick}
            >
              {" "}
              {displayedMovies.map((mov, index) => (
                <Card
                  key={index}
                  background="#252526"
                  borderLeft="3px solid rgb(255, 0, 0)"
                  borderRight="3px solid rgb(0, 128, 0)"
                  borderTop="3px solid rgb(255, 255, 0)"
                  borderBottom="3px solid rgb(255, 255, 0)"
                  m={{ base: 1, md: 1 }}
                  className="custom-card"
                  cursor="pointer"
                  onClick={() => navigate(`/movies-details/${mov._id}`)}
                >
                  <Image
                    className="banner-image"
                    src={mov.bannerImg}
                    alt="movie banner"
                    width="100%"
                    height="290px"
                    borderRadius="lg"
                  />
                  <Text
                    padding={2}
                    fontSize="large"
                    className="title"
                    color="white"
                    textAlign="center"
                    fontWeight="600"
                  >
                    {mov.movieName.slice(0, 150)}
                  </Text>
                </Card>
              ))}
            </Flex>
            <HStack spacing="24px" justify="center" margin="20px">
              <Button
                onClick={() => handleClick("prev")}
                isDisabled={currentPage === 1}
                colorScheme="blue"
              >
                Previous
              </Button>
              <Text color="white">
                Page {currentPage} of{" "}
                {Math.ceil(filteredMovies.length / ITEMS_PER_PAGE)}
              </Text>
              <Button
                onClick={() => handleClick("next")}
                isDisabled={
                  currentPage ===
                  Math.ceil(filteredMovies.length / ITEMS_PER_PAGE)
                }
                colorScheme="blue"
              >
                Next
              </Button>
            </HStack>
          </div>
        </div>
      </Center>
      {/* Footer Ad */}
      <Box
        as="footer"
        position="fixed"
        bottom="0"
        width="100%"
        background="black"
        padding="10px"
        textAlign="center"
      >
        <Box position="absolute" top="10px" right="10px">
          <Link
            to="https://refpa7921972.top/L?tag=d_3574986m_1236c_&site=3574986&ad=1236 "
            target="_blank"
          >
            <Button colorScheme="" onClick={() => setShowAd(false)}>
              X
            </Button>
          </Link>
        </Box>
        {showAd && (
          <Link
            to="https://refpa7921972.top/L?tag=d_3574986m_1236c_&site=3574986&ad=1236"
            target="_blank"
          >
            <Box>
              <Image
                src="https://refpa7921972.top/img/AdAgent_1/d0daa1d5-d517-455b-986b-3b49e7ab67a3.gif"
                alt="Ad Banner"
                width="100%"
                height="auto"
                className="betclass"
                onClick={() => setShowAd(false)}
              />
            </Box>
          </Link>
        )}
      </Box>
    </>
  );
};

export default Movies;
