import { Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { FaFacebookF, FaSquareInstagram, FaYoutube } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer = () => {
  const handleCardClick = () => {
    window.open("https://loanwaystore.com", "_blank");
    setTimeout(() => {
      window.open("https://loanwaystore.com", "_blank");
    }, 3000);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          ATOZMOVIES | Download All Bollywood and Hollywood Movies, South Indian
          Movies ...
        </title>
        <meta
          name="description"
          content="ATOZMOVIES is a platform offering downloads of Bollywood, Hollywood, and South Indian movies. It provides a variety of films across genres, making it a go-to destination for movie enthusiasts."
        />
        <meta
          name="keywords"
          content="Movie download
Free movies,
Bollywood movies,
Hollywood movies,
South Indian movies,
Latest movies,
HD movies,
Full movies,
Action movies,
Romance movies,
Thriller movies,
Comedy movies,
Movie streaming,
Movie trailers,
New releases,
Dubbed movies,
Tamil movies,
Telugu movies,
Hindi movies,
English movies,
4K movies,
Movie sites,
Online movies,
Blockbuster movies,
Film download,
Cinema movies,
Web series,
Movie collections,
Movie download links,
Free streaming,"
        />
        <link rel="canonical" href="https://atozmovies.store" />
      </Helmet>
      <div
        className="row"
        style={{
          width: "100%",
          borderTop: "1px solid white",
          marginTop: "10px",
          backgroundColor: "black",
        }}
      >
        <div className="col-md-2">
          <Link to="/disclimer">
            <Text
              fontSize="large"
              color="blue"
              marginTop={2}
              textAlign="center"
              cursor="pointer"
              onClick={handleCardClick}
            >
              Disclimer
            </Text>
          </Link>
        </div>
        <div className="col-md-2">
          <Link to="/join-group">
            <Text
              fontSize="large"
              marginTop={2}
              color="blue"
              textAlign="center"
              cursor="pointer"
              onClick={handleCardClick}
            >
              Join Our Group
            </Text>
          </Link>
        </div>
        <div className="col-md-2">
          <Link to="/how-to-download">
            <Text
              fontSize="large"
              marginTop={2}
              color="blue"
              textAlign="center"
              cursor="pointer"
              onClick={handleCardClick}
            >
              How to Download
            </Text>
          </Link>
        </div>
        <div className="col-md-2">
          <Link to="/privacy-policy">
            <Text
              fontSize="large"
              color="blue"
              textAlign="center"
              cursor="pointer"
              marginTop={2}
              onClick={handleCardClick}
            >
              Privacy Policy
            </Text>
          </Link>
        </div>

        <div className="col-md-2" style={{ textAlign: "right" }}>
          <Link to="https://www.instagram.com/invites/contact/?igsh=1gh8e6xn09n2x&utm_content=vblt2qw ">
            <Text
              fontSize="large"
              color="pink"
              textAlign="center"
              cursor="pointer"
              display="inline-block"
              marginRight="10px"
              marginTop={2}
              padding={1}
              border="1px solid white"
            >
              <FaSquareInstagram style={{ width: "20px", height: "auto" }} />
            </Text>
          </Link>

          <Link to="https://www.facebook.com/MovieHub4u143/">
            <Text
              fontSize="large"
              color="blue"
              textAlign="center"
              cursor="pointer"
              display="inline-block"
              marginRight="10px"
              border="1px solid white"
              marginTop={2}
              padding={1}
            >
              <FaFacebookF style={{ width: "20px", height: "auto" }} />
            </Text>
          </Link>

          <Link to="https://youtube.com/@moviehub4u-org?si=SwgvoHJk_WXvNo43">
            <Text
              fontSize="large"
              color="red"
              textAlign="center"
              cursor="pointer"
              display="inline-block"
              padding={1}
              border="1px solid white"
            >
              <FaYoutube style={{ width: "20px", height: "auto" }} />
            </Text>
          </Link>
        </div>
      </div>
      <div
        className="row"
        style={{
          width: "100%",
          backgroundColor: "black",
        }}
      >
        <div className="col-md-12 " style={{ width: "100%" }}>
          <Text
            fontSize="large"
            color="white"
            textAlign="center"
            cursor="pointer"
          >
            2024 © ATOZMOVIES | All Rights Reserved
          </Text>
        </div>
      </div>
    </>
  );
};

export default Footer;
