import React, { useEffect, useState } from "react";
import Navbar from "../Header/Navbar";
import MovieSliders from "../Screens/MovieSliders";
import Movies from "../Screens/Movies";
import Footer from "../Footer/Footer";
import OneSignal from "react-onesignal";
import { Helmet } from "react-helmet";

const Home = () => {
  const [initialized, setInitialized] = useState(false);

  OneSignal.init({ appId: "397fd7f6-bd33-4cf0-9582-dc8ae222ab2e" }).then(() => {
    setInitialized(true);
    OneSignal.Slidedown.promptPush();
    // do other stuff
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main" style={{ background: "black" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          ATOZMOVIES | Download All Bollywood and Hollywood Movies, South Indian
          Movies ...
        </title>
        <meta
          name="description"
          content="ATOZMOVIES is a platform offering downloads of Bollywood, Hollywood, and South Indian movies. It provides a variety of films across genres, making it a go-to destination for movie enthusiasts."
        />
        <meta
          name="keywords"
          content="Movie download
Free movies,
Bollywood movies,
Hollywood movies,
South Indian movies,
Latest movies,
HD movies,
Full movies,
Action movies,
Romance movies,
Thriller movies,
Comedy movies,
Movie streaming,
Movie trailers,
New releases,
Dubbed movies,
Tamil movies,
Telugu movies,
Hindi movies,
English movies,
4K movies,
Movie sites,
Online movies,
Blockbuster movies,
Film download,
Cinema movies,
Web series,
Movie collections,
Movie download links,
Free streaming,"
        />
        <link rel="canonical" href="https://atozmovies.store" />
      </Helmet>
      <Navbar />
      <hr style={{ color: "white" }} />
      <MovieSliders />
      <Movies />
      <Footer />
    </div>
  );
};

export default Home;
